<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'MissionOrders',
	components: {
	},
})
</script>

<template>
	<div class="mission-orders">
	</div>
</template>
